import React, { memo } from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
// sections for this page
// import Grid from '@material-ui/core/Grid';

// import { CardContent } from '@material-ui/core';
// import { Typography } from '@material-ui/core';

import { StaticImage } from 'gatsby-plugin-image';

import ScrollButton from '../components/ScrollButton';
// import { Card } from 'reactstrap';

import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const ProductDeliveryPage = memo(() => {
  const { backgroundImage123 } = useStaticQuery(
    graphql`
      query {
        backgroundImage123: file(relativePath: { eq: "project.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 50, webpOptions: { quality: 70 })
          }
        }
      }
    `
  );
  const image = getImage(backgroundImage123);

  const bgImage = convertToBgImage(image);

  return (
    <BackgroundImage
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
      className="masthead2"
    >
      <Layout fullMenu>
        <Seo
          title="Project Delivery"
          description="Adaptive project delivery is agnostic of method, its around dynamically changing how you deliver to an outcome. Surge can help with business analysts, project managers and delivery leads."
        />
        <ScrollButton />
        <article id="main">
          <header>
            <h2>Adaptive Project Delivery</h2>
            <p>
              Method agnostic delivery experts that focus on people to deliver
              business value fast
            </p>
          </header>
          <section className="wrapper style5">
            <div className="inner">
              <section>
                <header>
                  <h4>Delivery Leads</h4>
                  <p>Partnering with leaders to maximise benefit realisation</p>
                </header>

                <p>
                  86% of projects fail. Projects over promise and under deliver.
                  Sadly projects are set up for failure, debating methodology
                  instead of benefits. Surge Consulting provides seasoned
                  professionals for each step of your project delivery journey.
                  All projects and products should focus on a customer, how does
                  this solution solve a customers pain point? Bringing a human
                  approach to delivery will ensure a hypothesed future state is
                  well documented and ready to pivot based on new data.
                </p>

                <div className="row">
                  <div className="col-6 col-12-medium">
                    <ul>
                      <li>
                        <strong>WHO</strong> - is this project serving.
                      </li>
                      <li>
                        <strong>WHERE</strong> - on the customer journey will
                        this project look to help.
                      </li>
                      <li>
                        <strong>WHAT</strong> - problem are you solving.
                      </li>
                      <li>
                        <strong>HOW</strong> - will you solve the problem.
                      </li>
                      <li>
                        <strong>IN WHAT WAY</strong> - will you deliver this
                        project.
                      </li>
                    </ul>
                  </div>
                </div>
                <p>
                  It is only at the end does Surge Consulting look at
                  methodologies. Our approach? Whatever suits the delivery and
                  culture is always the best option. Our delivery leads can wear
                  many hats, Project managers for managing the end to end of
                  value, Scrum Master for deliverying greater team dynamics and
                  flow or Delivery Lead to ensure the value is delivered to the
                  end user or customer.
                </p>
              </section>
              <section>
                <ul className="features2">
                  <li className="icon solid fa-fingerprint">
                    <h3>Human</h3>
                    <p>
                      Designing Projects around the real needs of the users and
                      customers.
                    </p>
                  </li>
                  <li className="icon solid fa-laptop">
                    <h3>Adaptable</h3>
                    <p>
                      Fixed approaches lead to failure. Adapting to changes in
                      delivery is imperative.
                    </p>
                  </li>
                  <li className="icon solid fa-coffee">
                    <h3>Flexible</h3>
                    <p>
                      Delivery requires professionals that can flex their
                      toolkit to achieve the best outcome.
                    </p>
                  </li>
                </ul>
              </section>

              <div
                style={{
                  borderTop: '2px solid #000 ',
                  marginLeft: 20,
                  marginRight: 20,
                  marginBottom: 50,
                }}
              ></div>

              <section id="two" className="wrapper alt style1">
                <section className="spotlight">
                  <div className="image">
                    <StaticImage
                      src="../assets/images/tim.png"
                      alt="Tim Fluitsma"
                      placeholder="BLURRED"
                      quality="80"
                    />
                  </div>
                  <div className="content">
                    <h2>Delivery Practice</h2>
                    <p>
                      "If your projects are experiencing large time to market
                      deliverables, your customers are not willing to wait for
                      the pain points to be resolved or your people are not
                      motivated, adopting hybrid practices could be the solution
                      to increase customer and employee value. Adopting Agility
                      values takes experience to implement successfully, every
                      team and organisation is different, requiring a
                      distinctive style of leader, Surge will save you time by
                      identifying the teams culture and performance metrics,
                      make a quarterly plan and execute with precision. Call us
                      delivery leads, agile coaches, scrum masters or agile
                      facilitators, we focus on results that matter."
                    </p>
                    <a
                      href="https://www.linkedin.com/in/tim-fluitsma-91465190/"
                      className="button primary"
                    >
                      Contact Tim
                    </a>
                  </div>
                </section>
              </section>
            </div>
          </section>
        </article>
      </Layout>
    </BackgroundImage>
  );
});

export default ProductDeliveryPage;
